<template>
  <div class="container">
    <div class="row">
      <div class="col border border-1 bg-light rounded-3 mb-4">
        <p class="pt-4"><img :src="logo1" style="width: 300px; max-width: 100%; margin-left: auto; margin-right: auto; display: block;"></p>

        <p><span style="font-variant: small-caps">Distinct</span> is a <b>D</b>ynamic <b>I</b>n-Browser <b>S</b>ingle Sign-On <b>T</b>racer <b>I</b>nspecting <b>N</b>ovel <b>C</b>ommunication <b>T</b>echniques. It is a web-based Single Sign-On security analysis framework for modern in-browser communication techniques. It was developed for the paper <a href="/paper.pdf"><i class="bi bi-file-earmark-pdf"></i></a> <i>DISTINCT: Identity Theft using In-Browser Communications in Dual-Window Single Sign-On</i>. <span style="font-variant: small-caps">Distinct</span> is designed to monitor and analyze the in-browser communication techniques sending in-browser messages across browser windows at runtime. Its dynamic analysis approach automates the (1) capturing, (2) detection, (3) visualization, (4) security analysis, and (5) exploitation of Single Sign-On flows relying on novel in-browser communication techniques. Therefore, <span style="font-variant: small-caps">Distinct</span> executes the following two components.</p>
        <p><router-link class="link-dark" to="/monitor"><i class="bi bi-window"></i></router-link> <b>Live-Monitor</b> is a Chromium browser executing an extension that runs in the context of all browser windows, injects a monitoring script into each page, and gets access to the entire site, including its DOM and all APIs. In this browser, the Single Sign-On flow of a targeted website is executed and traced. The collected runtime data is transferred to the Communication-Inspector for the post-processing. You can use the <a href="https://test.distinct-sso.com"><i class="bi bi-bug"></i></a> vulnerable test service to execute an exemplary Single Sign-On flow with configurable initiators and receivers.</p>
        <p><router-link class="link-dark" to="/inspector"><i class="bi bi-inboxes"></i></router-link> <b>Communication-Inspector</b> is a Python backend and Vue.js frontend that gathers, stores, inspects, and visualizes the reports generated by Live-Monitor. Therefore, it serves an API and this web application. The API first receives the reports from Live-Monitor, and then triggers their post-processing. This web application is the primary control interface allowing analysts to start a new analysis and to provide the results in a searchable table and sequence diagram.</p>
        <p>Check out the paper <a href="/paper.pdf"><i class="bi bi-file-earmark-pdf"></i></a> or the source code <a href="https://github.com/RUB-NDS/DISTINCT"><i class="bi bi-github"></i></a> to find out more.</p>

        <h5 v-if="appMode == 'demo'"><i class="bi bi-exclamation-triangle"></i> Demo Mode</h5>
        <p v-if="appMode == 'demo'">This version of <span style="font-variant: small-caps">Distinct</span> runs in a demo mode. In this mode, Live-Monitor is disabled and you cannot execute and trace any Single Sign-On logins. You can only work with the Communication-Inspector to experiment with the prefilled sample data. Check out the GitHub repository <a href="https://github.com/RUB-NDS/DISTINCT"><i class="bi bi-github"></i></a> for more information on how you can setup your own instance of <span style="font-variant: small-caps">Distinct</span>, which you can use to trace and analyze any arbitrary Single Sign-On logins.</p>

        <h5><i class="bi bi-justify"></i> Abstract</h5>
        <p>Single Sign-On (SSO) protocols like OAuth 2.0 and OpenID Connect 1.0 are cornerstones of modern web security, and have received much academic attention. Users sign in at a trusted Identity Provider (IdP) that subsequently allows many Service Providers (SPs) to verify the users' identities. Previous research concentrated on the standardized — called <i>textbook</i> SSO in this paper — authentication flows, which rely on HTTP redirects to transfer identity tokens between the SP and IdP. However, modern web applications like single page applications may not be able to execute the textbook flow because they lose the local state in case of any HTTP redirects. By using novel browser technologies, such as <i>postMessage</i>, developers designed and implemented SSO protcols that were neither documented nor analyzed thoroughly. We call these implementations <i>dual-window</i> SSO flows.</p>
        <p>In this paper, we provide the first comprehensive evaluation of dual-window SSO flows and their unique requirements in comparison to textbook SSO. In particular, we focus on the In-Browser Communication (InBC) used to exchange authentication tokens between SPs and IdPs in iframes and popups. We automate our analysis by developing <span style="font-variant: small-caps">Distinct</span> — a tool that dynamically analyzes the in-browser JavaScript code executing as part of the SSO flow. <span style="font-variant: small-caps">Distinct</span> translates the flow into a sequence diagram depicting all communicating entities and their exchanged messages, highlights insecure communication channels, and quantifies novel threats in dual-window SSO flows. We found that 56% of the SPs in the Tranco top 1k list support dual-window SSO. Surprisingly, 28% of them implemented dual-window SSO without using official SDKs, leading to identity theft and XSS in 31% of these self-implemented SPs.</p>

        <h5><i class="bi bi-download"></i> Quick Installation</h5>
        <p>
          To install and execute <span style="font-variant: small-caps">Distinct</span>, run the following commands:<br>
          <code>
            $ git clone https://github.com/RUB-NDS/DISTINCT<br>
            $ cd ./DISTINCT/src<br>
            $ docker-compose build<br>
            $ docker-compose up<br>
          </code>
          Open the web interface on <code>http://localhost:9080</code> in your browser.<br>
          Please consider the <a href="https://github.com/RUB-NDS/DISTINCT"><i class="bi bi-github"></i></a> GitHub repository for more information on the installation and build process.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import logo1 from '../assets/logo-1.png'

export default {
  name: 'InfoView',
  data: function() {
    return {
      logo1: logo1,
      appMode: process.env['VUE_APP_MODE']
    }
  }
}
</script>

<style>

</style>
